import { Link } from 'gatsby';
import React,{useState,useEffect} from 'react'
import logo from "../../Images/logo/Logo_03.png"
// import logo2 from "../../Images/logo.png"

import store from "store"


function Header() {

	const [scrolled,setScrolled]=React.useState(false);
	const [cartItems, setcartItems] = useState(0)
    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 200 ){
          setScrolled(true);
        }
        else{
          setScrolled(false);
        }
      }
    
      React.useEffect(() => {
        if (store.get("products")) {
          setcartItems(store.get("products").length)
        }
      }, [store.get("products") && store.get("products").length])
      
      React.useEffect(() => {
        window.addEventListener('scroll',handleScroll)
         if (store.get("products")) {
           setcartItems(store.get("products").length)
         }
      })

      
    return (
      <div>
        <div className="header header-1">
          {/* <!-- MIDDLE BAR --> */}

          {/* <div className="middlebar d-none d-sm-block">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-4 col-md-4">
						<div className="logo">
							<img src={logo} alt="" width="100%" />
						</div>
					</div>
					<div className="col-8 col-md-8">
						<div className="contact-info">
						
							<div className="rs-icon-1">
								<div className="icon">
									<div className="fas fa-envelope"></div>
								</div>
								<div className="body-content">
									<div className="heading">Email Support :</div>
									<a href="mailto:gautamgears@gmail.com">gautamgears@gmail.com</a>
								</div>
							</div>


							
							<div className="rs-icon-1">
								<div className="icon">
									<div className="fas fa-phone-volume"></div>
								</div>
								<div className="body-content">
									<div className="heading">Call Support :</div>
									+91-161-2531075
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
 */}

          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="logo">
                  <img src={logo} alt="" width="100%" />
                </div>
              </div>
              <div className="col-md-6 d-none d-sm-block">
                <div className="contact-info">
                  <div className="row">
                    <div className="col-7">
                      <div className="rs-icon-1 ">
                        <div className="icon">
                          <div className="fas fa-envelope mr-2"></div>
                        </div>
                        <div className="body-content">
                          <div className="heading">Email </div>
                          <a href="mailto:gautamgears@gmail.com" style={{fontSize:"14px"}}>
                            kundanlalandsons@gmail.com
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-5">
                      <div className="rs-icon-1">
                        <div className="icon">
                          <div className="fas fa-phone-volume"></div>
                        </div>
                        <div className="body-content">
                          <div className="heading">Call Us </div>
                          <span style={{fontSize:"14px"}} >+91 161 5075115</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-1">
                <div id="google_translate_element"></div>
              </div>
            </div>
          </div>

          {/* <!-- NAVBAR SECTION --> */}
          <div
            className={scrolled ? "navbar-main stiky" : "navbar-main"}
            style={{ postion: "sticky", top: "0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <nav
                    id="navbar-example"
                    className="navbar sticky-top navbar-expand-lg navbar-bg"
                  >
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNavDropdown"
                      aria-controls="navbarNavDropdown"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarNavDropdown"
                    >
                      <ul className="navbar-nav">
                        {/* <li className="nav-item dropdown">
			                    <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						          Home
						        </a>
			                    <div className="dropdown-menu">
			                    	<a className="dropdown-item" href="index.html">Homepage 1</a>
	          						<a className="dropdown-item" href="index-2.html">Homepage 2</a>
	          						<a className="dropdown-item" href="index-3.html">Homepage 3</a>
							    </div>
			                </li> */}

                        <li className="nav-item ">
                          <Link
                            activeClassName="active"
                            className="nav-link"
                            to="/"
                          >
                            Home
                          </Link>
                        </li>

                        <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Company
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              activeClassName="active"
                              className="dropdown-item"
                              to="/company/about"
                            >
                              About
                            </Link>
                            <Link
                              activeClassName="active"
                              className="dropdown-item"
                              to="/company/history"
                            >
                              History
                            </Link>
                          </div>
                        </li>

                        <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            href="/products/category"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Products
                          </a>
                          <div className="dropdown-menu">
                            <div className="dropdown dropright">
                              <button
                                type="button"
                                className="dropdown-item dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                Sprockets
                              </button>
                              <div className="dropdown-menu">
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/sprockets/automotive"
                                >
                                  Automotive Sprockets
                                </Link>
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/sprockets/agriculture"
                                >
                                  Agricultural Sprockets
                                </Link>
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/sprockets/industrial"
                                >
                                  Industrial Sprockets
                                </Link>
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/sprockets/textile"
                                >
                                  Textile Sprockets
                                </Link>
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/sprockets/machinery"
                                >
                                  Machinery Sprockets
                                </Link>
                              </div>
                            </div>

                            <div className="dropdown dropright">
                              <button
                                type="button"
                                className="dropdown-item dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                Gears
                              </button>
                              <div className="dropdown-menu">
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/gears/automotive"
                                >
                                  Automotive Gears
                                </Link>
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/gears/agriculture"
                                >
                                  Agricultural Gears
                                </Link>
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/gears/industrial"
                                >
                                  Industrial Gears
                                </Link>
                              </div>
                            </div>

                            <div className="dropdown dropright">
                              <button
                                type="button"
                                className="dropdown-item dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                Spline Shafts
                              </button>
                              <div className="dropdown-menu">
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/splineshaft/agriculture"
                                >
                                  Agricultural Spline Shaft
                                </Link>
                                <Link
                                  activeClassName="active"
                                  className="dropdown-item"
                                  to="/category/splineshaft/automotive"
                                >
                                  Automotive Spline Shaft
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="nav-item">
                          <Link
                            activeClassName="active"
                            className="nav-link"
                            to="/infra"
                          >
                            Infrastructure
                          </Link>
                        </li>
                        <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Quality
                          </a>
                          <div className="dropdown-menu">
                            <Link
                              activeClassName="active"
                              className="dropdown-item"
                              to="/achievements"
                            >
                              Achievements
                            </Link>
                            <Link
                              activeClassName="active"
                              className="dropdown-item"
                              to="/quality-assurance"
                            >
                              Quality Assurance
                            </Link>
                          </div>
                        </li>

                        {/* <li className="nav-item">
                          <Link
                            activeClassName="active"
                            className="nav-link"
                            to="/quality"
                          >
                            Quality
                          </Link>
                        </li> */}

                        <li className="nav-item">
                          <Link
                            activeClassName="active"
                            className="nav-link"
                            to="/contact"
                          >
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <Link
                      activeClassName="active"
                      to="/cart"
                      className="btn btn-primary btn-navbar "
                      style={{ position: "relative" }}
                    >
                      <i className="fas fa-shopping-cart"></i>
                      <span style={{ position: "absolute", top: "10px" }}>
                        {cartItems}
                      </span>
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* header end */}
      </div>
    )
}

export default Header
