import React,{useState} from 'react'
import Banner from '../Components/reusable/Banner'
import Layout from "../Components/reusable/Layout"
import AxiosRequest from '../Components/Utility/AxiosRequest'
import { contact_submit } from '../Components/Utility/Url'

function Contact() {
	const [ formData , setformData] = useState({
		name:"",
		email:"",
		phone:"",
		company:"",
		countrycode:"",
		address:"",
		message:""

	})

	const handleChange = (e) =>{
		setformData({
			...formData,
			[e.target.id]:e.target.value
		})
	}

	const handleSubmit = (e)=>{
		e.preventDefault();
		console.log(formData);
	AxiosRequest(
    contact_submit,
    {
      user_name: formData.name,
      user_email: formData.email,
      company: formData.company,
      countrycode: formData.countrycode,
      phone_no: formData.phone,
      address: formData.address,
      message: formData.message,
    },
    res => {
		console.log(res);
	}
  )
	}
    return (
      <Layout>
        <Banner title="Contact Us" />

        <div id="contact" className="section">
          <div className="content-wrap">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  {/* <div className="widget info-detail">
							<h4 className="section-heading text-black no-after mb-3">
								Download <span className="text-primary">Brochure</span>
							</h4>
							<div className="widget download">
								<ul className="category-nav">
									<li><a href="#" className="btn btn-secondary btn-block btn-sidebar"><span className="fas fa-file-pdf mr-3"></span> Company Profile</a></li>
									<li><a href="#" className="btn btn-secondary btn-block btn-sidebar"><span className="fas fa-file-pdf mr-3"></span> Product Knowledge</a></li>
									<li><a href="#" className="btn btn-secondary btn-block btn-sidebar"><span className="fas fa-file-pdf mr-3"></span> Voluptate Velit</a></li>
								</ul>
							</div>
						</div> */}

                  <div className="widget info-detail">
                    <h4 className="section-heading text-black no-after mb-3">
                      Contact <span className="text-primary">Details</span>
                    </h4>
                    <ul className="list-info">
                      <li>
                        <div className="info-icon text-black">
                          <span className="fas fa-user"></span>
                        </div>
                        <div className="info-text">Mr Kapil Gupta</div>
                      </li>
                      <li>
                        <div className="info-icon text-black">
                          <span className="fas fa-map-marked-alt"></span>
                        </div>
                        <div className="info-text">
                          E-632, 633, Phase-VII, Focal Point, Ludhiana – 141010, Punjab, India.
                        </div>
                      </li>
                      <li>
                        <div className="info-icon text-black">
                          <span className="fas fa-phone-alt"></span>
                        </div>
                        <div className="info-text">+91 161 5075115</div>
                      </li>
                      <li>
                        <div className="info-icon text-black">
                          <span className="fas fa-mobile-alt"></span>
                        </div>
                        <div className="info-text"> +91-9875941908</div>
                      </li>
                      <li>
                        <div className="info-icon text-black">
                          <span className="fas fa-envelope"></span>
                        </div>
                        <div className="info-text">
                           sprockets@kundanlalandsons.com <br /> kundanlalandsons@gmail.com 
                        </div>
                      </li>

                      <li>
                        <div className="info-icon text-black">
                          <span className="fas fa-globe"></span>
                        </div>
                        <div className="info-text">
                          <a href="http://chainsprockets.co.in">
                            www.chainsprockets.co.in
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-12 col-md-8 pleft">
                  <h4 className="text-black mt-0">Send a Message here</h4>

                  <form
                    action="#"
                    className="form-contact"
                    id="contactForm"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            value={formData.name}
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            id="name"
                            required
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            value={formData.email}
                            onChange={handleChange}
                            type="email"
                            className="form-control"
                            id="email"
                            required
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            value={formData.company}
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            id="company"
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <label>Phone Number (with country code)</label>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                value={formData.countrycode}
                                onChange={handleChange}
                                type="number"
                                className="form-control"
                                id="countrycode"
                                placeholder="+91"
                                required
                              />

                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="form-group">
                              <input
                                value={formData.phone}
                                onChange={handleChange}
                                type="text"
                                className="form-control"
                                id="phone"
                                required
                              />
                              <div className="help-block with-errors"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        value={formData.address}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="address"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                    <div className="form-group">
                      <label>Your Message</label>
                      <textarea
                        value={formData.message}
                        onChange={handleChange}
                        id="message"
                        className="form-control"
                        rows="4"
                      ></textarea>
                      <div className="help-block with-errors"></div>
                    </div>
                    <div className="form-group">
                      <div className="text-left">
                        <div id="success"></div>
                        <button type="submit" className="btn btn-primary">
                          SEND MESSAGE
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section banner-page">
          <div className="content-map">
            <div className="google-maps">
            

              <iframe
                title="Kundan Lal and Sons"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3424.505708727846!2d75.9355885!3d30.872511099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a9dee6b1a9301%3A0x239ae6b0da872e27!2sKundan%20Lal%20And%20Sons!5e0!3m2!1sen!2sin!4v1737716024416!5m2!1sen!2sin"
              ></iframe>
            </div>
          </div>
        </div>
      </Layout>
    )
}

export default Contact
