import React from 'react'
import logo from "../../Images/logo/footerlogo.png"
import { Link } from "gatsby";
// import logo2 from "../../Images/logo.png"
function Footer() {
    return (
      <div className="footer">
        <div className="content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="footer-item" style={{ paddingTop: "25px" }}>
                  <img
                    src={logo}
                    alt="logo bottom"
                    className="logo-bottom"
                    width="270px"
                  />
                  <div className="spacer-30"></div>
                  {/* <p>Nam eu laoreet dui, quis aliquet neque. Phasellus accumsan nibh nibh, sit amet vehicula mauris porta quis.</p>


							<ul className="list-info my-4">
								<li>
									<div className="info-icon uk21">
										<span className="fas fa-phone-alt"></span>
									</div>
									<div className="info-text uk21"><strong>+91 7814105115 </strong></div> </li>
							</ul> */}

                  <div className="sosmed-icon d-inline-flex">
                    <Link to="https://www.facebook.com/partapchowk9814305115">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    {/* <Link to="/">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link to="/">
                      <i className="fab fa-instagram"></i>
                    </Link> */}
                    <Link to="https://www.linkedin.com/in/kundan-lal-and-sons-36298220a/">
                      <i className="fab fa-linkedin"></i>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="footer-item">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="footer-title">Products</div>
                      <ul className="list">
                        <li>
                          <i
                            className="fas fa-chevron-right"
                            style={{ color: "#CD2D1D" }}
                          ></i>
                          <Link to="/category/sprockets" title="Home">
                            Sprockets
                          </Link>
                        </li>
                        <li>
                          <i className="fas fa-chevron-right" style={{ color: "#CD2D1D" }}></i>
                          <Link to="/category/gears" title="Pages">
                            Gears
                          </Link>
                        </li>
                        <li>
                          <i className="fas fa-chevron-right" style={{ color: "#CD2D1D" }}></i>
                          <Link to="/category/splineshaft" title="Blog">
                            Spline Shafts
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <div className="footer-title">Quick Links</div>
                      <ul className="list">
                        <li>
                          <i className="fas fa-chevron-right" style={{ color: "#CD2D1D" }}></i>
                          <Link to="/company/history" title="My Account">
                            History
                          </Link>
                        </li>
                        <li>
                          <i className="fas fa-chevron-right" style={{ color: "#CD2D1D" }}></i>
                          <Link to="/company/about" title="Help Desk">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <i className="fas fa-chevron-right" style={{ color: "#CD2D1D" }}></i>
                          <Link to="/quality" title="Support">
                            Quality
                          </Link>
                        </li>
                        <li>
                          <i className="fas fa-chevron-right" style={{ color: "#CD2D1D" }}></i>
                          <Link to="/infra" title="Your Order">
                            Infrastructure
                          </Link>
                        </li>
                        <li>
                          <i className="fas fa-chevron-right" style={{ color: "#CD2D1D" }}></i>
                          <Link to="/contact" title="Your Order">
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="footer-item">
                  <div className="footer-title">Contact Us</div>

                  <ul className="list-info">
                    <li>
                      <div className="info-icon ">
                        <span className="fas fa-map-marked-alt"></span>
                      </div>
                      <div className="info-text">
                        E-632, 633, Phase-VII, Focal Point, Ludhiana – 141010, Punjab, India.
                      </div>
                    </li>
                    <li>
                      <div className="info-icon ">
                        <span className="fas fa-phone-alt"></span>
                      </div>
                      <div className="info-text">
                      +91 161 5075115 , +91 9875941908
                      </div>
                    </li>
                    <li>
                      <div className="info-icon ">
                        <span className="fas fa-envelope"></span>
                      </div>
                      <div className="info-text">sprockets@kundanlalandsons.com </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fcopy">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <p className="mt-3 text-center">
                  © {new Date().getFullYear()} | Copyright KUNDAN LAL AND SONS |
                  Allrights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Footer
